/* eslint-disable radix */
import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import AuthService from "../services/AuthService";
import Config from "../config";
import Constants from "./Constants";

const authService = new AuthService(Config.apiBase);
/**
 * Add's hyphen after specified digits in the number.
 * @param {Number} number
 */
export const addHyphenToNumber = (number, position) => {
  let value = number || "";
  const regEx = new RegExp(`(\\d{${position}})`, "g");
  value = value.replace(/([^0-9 -\s])/gm, "").replace(/\s+/g, "");
  value = value && value.replace(/([^0-9 -\s])/gm, "").replace(/\s+/g, "");
  if (value && (value.length > (position + 1) || value.length < (position + 1))) {
    value = value.replace(/-/g, "");
  }
  if (value && value.length > position && value.indexOf("-") !== position) {
    value = value.replace(regEx, "$1-");
  }
  return value;
};

export const parseFilterParams = (filterElement = []) => {
  let filterKeys;
  let filterQueryParams = "";
  if (filterElement && filterElement[1]) {
    if (filterElement[1] === true) {
      filterQueryParams = "filter=true";
    } else {
      filterKeys = Object.keys(filterElement[1]);
      if (filterKeys.length) {
        filterQueryParams = `filter=${
          filterKeys.map((obj) => obj).join(",")
        }&${
          filterKeys
            .map(
              (obj) => `${obj}=${
                filterElement[1][obj]
                  .map((ele) => encodeURIComponent(ele))
                  .filter((o) => o !== "")
                  .join(",")}`,
            )
            .filter((o) => o !== "")
            .join("&")}`;
      }
    }
  }
  return filterQueryParams;
};

export const parseOtherQueryParams = (queryParam = []) => {
  let otherQueries;
  if (queryParam.length > 0) {
    otherQueries = queryParam
      .map((obj) => {
        if (obj[1] !== undefined && obj[1] !== null) {
          return obj.join("=");
        }
        return undefined;
      })
      .filter((o) => o)
      .join("&");
  }
  return otherQueries;
};

export const queryParamParser = (data=[]) => {
  if(data.length){
    let queryParam = data;
    const filterElement = queryParam.find((obj) => obj[0] === "filter");
    queryParam = queryParam.filter((obj) => obj[0] !== "filter");

    const filterQueryParams = parseFilterParams(filterElement);

    const otherQueries = parseOtherQueryParams(queryParam);

    let params = "?";
    if (filterQueryParams) {
      params = `${params + filterQueryParams}&`;
    }
    return params + otherQueries;
  }
  return "";
};

export const redirectToPage = (data) => {
  const { routes } = Constants;
  const {
    user_access: {
      module={}
    } = {},
  } = data;
  switch(true){
  case module.order:
    return routes.ordersList.url;
  case module.room:
    return  routes.roomsList.url;
  case module.customer:
    return routes.customersList.url;
  case module.assignment:
    return routes.assignmentsList.url;
  case module.services:
    return routes.servicesList.url;
  case module.calendar:
    return routes.calendar.url;
  case module.users:
    return routes.usersList.url;
  default:
    return routes.pageNotAccessible.url;
  }
};

export const isLoggedIn = () => authService.isAuthenticated();

export const isAdmin = () => authService.isAdminUser();

export const isCustomer = () => authService.isCustomerUser();

const { input: { format } } = Constants;
export const formatDate = (date = "", type = format.date) => moment(date).format(type);
export const formatUTCDate = (date = "", type = format.date) => moment.utc(date).format(type);
export const formatUTCDateToLocal = (date = "", type = format.date) => moment.utc(date).local().format(type);

export const parseTime = (value = "") => {
  const timeIn24Hour = value.replace(":", "").replace(" am", "").replace(" pm", "");
  const hour = timeIn24Hour.slice(0, 2) > 12
    ? Number(timeIn24Hour.slice(0, 2)) % 12 : Number(timeIn24Hour.slice(0, 2));
  const min = Number(timeIn24Hour.slice(2));
  return { hour, min };
};

export const getStartAndEndDateOfaMonth = (date) => {
  const startDate = moment(date);
  const endDate = moment(date);
  return { startDate, endDate };
};

export const changeDateFormat = (date, from, to = format.date) => moment(date, from).format(to);

export const converTimeFrom12To24 = (value) => {
  const convertedTime = moment(value, "hh:mm A").format(format.twentyFourHourFormat);
  return convertedTime;
};

export const converTimeFrom24To12 = (hour) => {
  const convertedTime = moment(hour, format.twentyFourHourFormat).format(format.dottedHourFormat);
  return convertedTime;
};

export const mapDogSizeToDuration = (size, duration) => {
  switch (size) {
  case "mini":
    return `${duration?.duration?.mini || 0} min`;
  case "small":
    return `${duration?.duration?.small || 0} min`;
  case "medium":
    return `${duration?.duration?.medium || 0} min`;
  case "large":
    return `${duration?.duration?.large || 0} min`;
  default:
    return null;
  }
};

export const timelineLabels = (interval, period, start, end, dateSelected, fullDay = true) => {
  const currentDate = moment(new Date()).format(format.date);
  const selectedDate = moment(dateSelected).format(format.date);
  let startTime = start;
  let endTime = end;
  if (currentDate === selectedDate && fullDay) {
    const dates = currentDayTime(startTime, endTime);
    startTime = dates.startTime;
    endTime = dates.endTime;
  }

  let startTimeMoment = moment(startTime, "HH:mm");
  let minutes = startTimeMoment.minutes();
  let roundedMinutes = Math.round(minutes / 15) * 15;
  startTimeMoment.minutes(roundedMinutes).seconds(0);

  if (roundedMinutes === 60) {
    startTimeMoment.add(1, "hour").minutes(0);
  }

  const periodsInADay = moment.duration(moment(endTime, "HH:mm").diff(startTimeMoment)).as(period);

  const timeLabels = [];

  for (let i = 0; i <= Math.floor(periodsInADay / 15); i += 1) {
    const incrementedStartTime = moment(startTimeMoment).add(i * 15, period);
    const endTimeMoment = moment(incrementedStartTime).add(interval, period);

    if (endTimeMoment.diff(moment(endTime, "HH:mm")) <= 0) {
      timeLabels.push(`${incrementedStartTime.format("HH:mm")} - ${endTimeMoment.format("HH:mm")}`);
    }
  }
  return timeLabels;
};

const currentDayTime = (startTime, endTime) => {
  let currentTime = "";
  const currentMins = moment(new Date()).minutes() / 15;
  const currentQuarter = parseInt(currentMins, 5);
  if (currentQuarter !== 3) {
    currentTime = moment(new Date()).set({ minute: (currentQuarter + 1) * 15 }).format("HH:mm");
  } else {
    currentTime = moment(new Date()).add(1, "hours").set({ minute: 0 })
      .format("HH:mm");
  }
  const currentTimeString = currentTime;
  currentTime = Number(currentTime.split(":")[0] * 60) + Number(currentTime.split(":")[1]);
  const availableStartTime = startTime && startTime.includes(":") ? Number(startTime.split(":")[0]) * 60 + Number(startTime.split(":")[1]) : 0;
  const availableEndTime = endTime && endTime.includes(":") ? Number(endTime.split(":")[0]) * 60 + Number(endTime.split(":")[1]) : 0;
  if ((Number(currentTime) > Number(availableStartTime))) {
    if (Number(currentTime) > Number(availableEndTime)) {
      endTime = "";
      startTime = "";
    } else {
      startTime = currentTimeString;
    }
  }
  return {startTime, endTime}
}

export const mapSizeToDog = (height) => {
  switch (height) {
  case "mini":
    return "<30 cm";
  case "small":
    return "<35 cm";
  case "medium":
    return "36-45 cm";
  case "large":
    return ">46 cm";
  default:
    return null;
  }
};
export const getDogSizeTranslation = (size) => {
  const { language } = Constants;
  switch (size) {
  case "mini":
    return  language.size_dog_mini;
  case "small":
    return language.size_dog_small;
  case "medium":
    return language.size_dog_medium;
  case "large":
    return language.size_dog_large;
  default:
    return null;
  }
};

export const mapDogSizeToPrice = (size, price) => {
  switch (size) {
  case "mini":
    return  `${price?.price?.mini || 0} kr`;
  case "small":
    return  `${price?.price?.small || 0} kr`;
  case "medium":
    return  `${price?.price?.medium || 0} kr`;
  case "large":
    return  `${price?.price?.large || 0} kr`;
  default:
    return "0 kr";
  }
};

export const calculateTime = (t) => {
  const ap = ["am", "pm"];
  const hh = Math.floor(t / 60); // getting hours of day in 0-24 format
  const mm = (t % 60); // getting minutes of the hour in 0-55 format
  let meridiem = Math.floor(hh / 12);
  meridiem = meridiem > 1 ? meridiem / 2 : meridiem;
  return `${(`0${hh % 12 === 0 ? 12 : hh % 12}`).slice(-2)}:${(`0${mm}`).slice(-2)} ${ap[meridiem]}`;
};

// convert dd-mm-yyyy to yyyy-mm-dd
export const convertDateFormat = (date) => {
  const newDate = moment(date, Constants.input.format.dayToYear);
  const result = newDate.format(format.date);
  return result;
};

const {
  input: {
    format: { dayToYear, time },
  },
} = Constants;

export const extractDateTimeResult = (responseData) => {
  let reservedDates = {};
  responseData?.map((obj) => {
    const { start_datetime: startDate = "", end_datetime: endDate = "" } = obj;
    const date = formatDate(startDate, dayToYear);
    const start = obj?.start_datetime;
    const end = obj?.end_datetime;
    const from = formatDate(startDate, time);
    const to = formatDate(endDate, time);
    const serviceProviderId = obj?.service_provider?.id;
    if (reservedDates[date]) {
      reservedDates[date].push({
        from,
        to,
        start,
        end,
        serviceProviderId,
      });
    } else {
      reservedDates = {
        ...reservedDates,
        [date]: [
          {
            from,
            to,
            start,
            end,
            serviceProviderId,
          },
        ],
      };
    }
    return obj;
  });
  return reservedDates;
};

export const stepRoute = (step = 1) => {
  switch (step) {
  case 1:
    return Constants.routes.chooseService.url;
  case 2:
    return Constants.routes.chooseDog.url;
  case 3:
    return Constants.routes.chooseCenter.url;
  case 4:
    return Constants.routes.chooseTimeDate.url;
  case 5:
    return Constants.routes.orderSummary.url;
  default:
    return Constants.routes.chooseService.url;
  }
};

export const groupSlotData = (data) => {
  let slots = [];
  data.map((obj) => {
    const {
      sp_id: actualServiceProviderId = "",
    } = obj;

    const serviceProviderId = [actualServiceProviderId];
    slots.push({ ...obj, serviceProviderIds: serviceProviderId, slotDetails: [obj] });
    return obj;
  });
  return slots;
};

export const groupAvailabilities = (data) => {
  let tags = [];
  let fulldayTags = [];
  data.map((obj) => {
    const {
      date = "", sp_id: actualServiceProviderId = "", is_full_day_available : isAvailable = false,
    } = obj;
    const serviceProviderId = [actualServiceProviderId];
    if(isAvailable) {
      if (fulldayTags.find((ob) => ob.date === date)) {
        fulldayTags = fulldayTags.map((ob) => {
          if (ob.date === date) {
            if (ob.serviceProviderIds.includes(actualServiceProviderId)) {
              return { ...ob };
            }
            const serviceProviderIds = ob.serviceProviderIds.includes(serviceProviderId)
              ? serviceProviderId : [...ob.serviceProviderIds, ...serviceProviderId];
            return { ...ob, serviceProviderIds };
          }
          return ob;
        });
      } else {
        fulldayTags.push({
          date,
          serviceProviderIds: serviceProviderId,
          start: `${date} 00:00:00`,
          end: `${date} 01:00:00`,
          showFullDayAvailbityButton: true,
          allDay: true,
          name: "available",
          slotDetails: [...data.filter((slot) => slot.date === date)],
        });
      }
    } else {
      if (tags.find((o) => o.date === date)) {
        tags = tags.map((o) => {
          if (o.date === date) {
            if (o.serviceProviderIds.includes(actualServiceProviderId)) {
              return { ...o };
            }
            const serviceProviderIds = o.serviceProviderIds.includes(serviceProviderId)
              ? serviceProviderId : [...o.serviceProviderIds, ...serviceProviderId];
            return { ...o, serviceProviderIds };
          }
          return o;
        });
      } else {
        tags.push({
          date,
          serviceProviderIds: serviceProviderId,
          start: `${date} 00:00:00`,
          end: `${date} 01:00:00`,
          showAvailbityButton: true,
          allDay: true,
          name: "available",
          slotDetails: [...data.filter((slot) => slot.date === date)],
        });
      }
    }
    return obj;
  });
  const uniqueAvailabilities = [...new Set(tags.map((item) => item)), ...new Set(fulldayTags.map((item) => item))];
  return uniqueAvailabilities;
};

export const fetchAvailabilityLocalDateConvertedJson = (data) => {
  const { date: startDate, end_time: endTime, start_time: startTime, display = "" } = data;
  let {
    service_provider_slot: serviceProviderSlot = null,
    service_provider_available_slots: serviceProviderAvailableSlot = null,
    get_breaks: breaks = null,
  } = data;
  if (serviceProviderSlot) {
    serviceProviderSlot = {
      ...serviceProviderSlot,
      end_time: moment.utc(`${serviceProviderSlot.date} ${serviceProviderSlot.end_time}`).local().format("HH:mm"),
      start_time: moment.utc(`${serviceProviderSlot.date} ${serviceProviderSlot.start_time}`).local().format("HH:mm"),
    };
  }
  if (serviceProviderAvailableSlot) {
    serviceProviderAvailableSlot = serviceProviderAvailableSlot.map((o) => ({
      ...o,
      end_time: moment.utc(`${o.date} ${o.end_time}`).local().format("HH:mm"),
      start_time: moment.utc(`${o.date} ${o.start_time}`).local().format("HH:mm"),
    }));
  }
  if (breaks) {
    breaks = breaks.map((o) => ({
      ...o,
      break: JSON.parse(o.break).map(([breakStartTime, breakEndTime]) => [
        moment.utc(`${startDate} ${breakStartTime}`).local().format("HH:mm"),
        moment.utc(`${startDate} ${breakEndTime}`).local().format("HH:mm"),
      ]),
    }));
  }
  return {
    ...data,
    end_time: moment.utc(`${startDate} ${endTime}`).local().format("HH:mm"),
    start_time: moment.utc(`${startDate} ${startTime}`).local().format("HH:mm"),
    date: moment.utc(`${startDate} ${startTime}`).local().format("yyyy-MM-DD"),
    service_provider_slot: serviceProviderSlot,
    service_provider_available_slots: serviceProviderAvailableSlot,
    get_breaks: breaks,
    display: serviceProviderSlot?.is_full_day_available ? "none" : display,
    is_full_day_available: serviceProviderSlot?.is_full_day_available || data?.is_full_day_available,
    hideAvailability : serviceProviderSlot?.is_full_day_available || data?.is_full_day_available,
  };
};

export const saveAvailabilityUTCDateConvertedJson = (availability) => {
  const { startDate, time: [selectedStartTime, selectedEndTime] } = availability;
  let { brake: slotBreak = [] } = availability;
  if (slotBreak) {
    slotBreak = slotBreak.map(([breakStartTime, breakEndTime]) => [
      moment(`${startDate} ${breakStartTime}`).utc().format("HH:mm"),
      moment(`${startDate} ${breakEndTime}`).utc().format("HH:mm"),
    ]);
  }
  return {
    ...availability,
    date: moment(startDate).format("yyyy-MM-DD"),
    time: [
      moment(`${startDate} ${selectedStartTime}`).utc().format("HH:mm"),
      moment(`${startDate} ${selectedEndTime}`).utc().format("HH:mm"),
    ],
    brake: slotBreak,
  };
};

export const checkPermission = (data = [], value = "") => {
  const values = data.map((o) => o[value]);
  switch (true) {
  case values.includes(Constants.MANAGE):
    return true;
  case values.includes(Constants.WRITE):
    return true;
  case values.includes(Constants.READ):
    return true;
  default:
    return false;
  }
};

export const checkRightPermission = (data = [], value = "", right = "") => {
  const values = data.map((o) => o[value]);
  return values.includes(right);
};

export const formatUtcToLocal = (value,type) => {
  return moment.utc(value).local().format(type);
};

export const getUserInfo = (userDetails) =>{
  if(userDetails?.user_access){
    return userDetails
  } else if(localStorage.userInfo) {
    return JSON.parse(localStorage.userInfo)
  }
  return {}
}

export const calculateVATAmount = (totalPrice) => (Math.round(totalPrice - ((totalPrice) / 1.25)).toFixed(2));

export const convertSEK = (price) => {
  const formatPriceToTwoDecimals = (Math.round(price * 100) / 100).toFixed(2)
  return formatPriceToTwoDecimals ? formatPriceToTwoDecimals.toString().replaceAll(".", ",").replace(/,00$/, "") : "";
};

export const getExceptionPopupContent = () => {
  return {
    header: <FormattedMessage id="common_exception_header"/>,
    message: <FormattedMessage id="common_exception_message"/>
  };
}

export const getSubscriptionFilters = (filters) => {
  if (filters) {
    return Object.keys(filters).reduce((result, key) => {
      let newKey = `whereIn[${key}]`;
      if (key === "dog_size" || key === "subscription_days" || key === "gender") {
        newKey = Constants.SUBSCRIPTION_FILTER_KEYS[key];
      }
      result[newKey] = filters[key];
      return result;
    }, {});
  }
  return filters;
}
